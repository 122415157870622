import {css} from 'linaria';
import React from 'react';

type Props = {
  errors: ReadonlyArray<string> | null;
};

const errors = css`
  background-color: #ffeeeb;
`;

export default function Errors(props: Props) {
  if (!props.errors || props.errors.length === 0) {
    return null;
  }

  return (
    <div className={`px-3 py-3 ${errors}`}>
      <strong>Some problems were encountered:</strong>
      <br />
      <ul>
        {props.errors.map(error => (
          <li key={error} dangerouslySetInnerHTML={{__html: error}} />
        ))}
      </ul>
    </div>
  );
}
