import React from 'react';

import {YearResult} from '../types/ResultTypes';
import {table} from '../sharedStyles';

type Props = {
  aggregates: {[year: number]: YearResult};
  yearsToShow: ReadonlyArray<number>;
};

export default class ResultsAggregates extends React.Component<Props> {
  render() {
    if (!this.props.aggregates) {
      return null;
    }

    return (
      <table className={table}>
        <thead>
          <tr>
            <th>Year</th>
            <th>Aggregate</th>
            <th>ATAR</th>
          </tr>
        </thead>
        <tbody>{this.props.yearsToShow.map(this._renderYear, this)}</tbody>
      </table>
    );
  }

  _renderYear(year: number) {
    const yearData = this.props.aggregates[year];
    if (!yearData || yearData.ignore) {
      return null;
    }
    return (
      <tr key={year}>
        <td>{year}</td>
        <td>{yearData.aggregate}</td>
        <td>{yearData.enter}</td>
      </tr>
    );
  }
}
