import {XCircleIcon} from '@primer/octicons-react';

import React from 'react';

import {Subject} from '../types';

type Props = Readonly<{
  inputID: string;
  subject: Subject;

  onDeleteSubject: (id: number) => void;
}>;

export default function SubjectName(props: Props) {
  return (
    <span className="subject_name">
      <button
        className="cursor-pointer align-text-bottom"
        title="Delete"
        onClick={() => props.onDeleteSubject(props.subject.ID_SUBJECT)}>
        <XCircleIcon fill="#f87171" verticalAlign="middle" />
      </button>{' '}
      <label className="text-base inline" htmlFor={props.inputID}>
        {props.subject.name}
      </label>
    </span>
  );
}
