import {useEffect, useState, useRef, useMemo} from 'react';

import {State as InputState} from './useCalculatorInputReducer';
import {ResultsData} from '../types/ResultTypes';
import serializeSubjects from '../utils/serializeSubjects';
import {logCalculation} from '../analyticsLogger';
import useAjax from './useAjax';

export default function useCalculationResult(
  input: InputState,
): [ResultsData | null, boolean] {
  // No point sending a request if there's no input
  const shouldLoad =
    input.subjects.length !== 0 &&
    input.subjects.some(
      subject =>
        (subject.fields.score != null && subject.fields.score !== 0) ||
        subject.fields.grade != null,
    );
  const data = useMemo(
    () => ({
      ...input.extraData,
      ...serializeSubjects(input.subjects),
    }),
    [input.extraData, input.subjects],
  );
  let [isLoading, response, error] = useAjax<ResultsData>({
    data,
    shouldSendRequest: shouldLoad,
    url: 'api/calculate.json',
  });

  if (error != null && response == null) {
    response = {
      errors: [error.message],
      subjects: [],
      years: {},
    };
  }

  useEffect(() => {
    if (shouldLoad) {
      logCalculation();
    }
  }, [shouldLoad, input.subjects]);

  return [response, isLoading];
}
