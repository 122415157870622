import React, {ChangeEvent} from 'react';

import SubjectName from './SubjectName';
import {Subject, SubjectFields} from '../types';

export type Props = {
  subject: Subject;

  onDeleteSubject: (id: number) => void;
  onUpdateSubject: (id: number, fields: SubjectFields) => void;
};

export default class SubjectBasic extends React.Component<Props> {
  render() {
    const subject = this.props.subject;
    const inputID = 'score_' + subject.ID_SUBJECT;
    return (
      <li
        className="px-2 flex justify-between items-center"
        id={`subject_${subject.ID_SUBJECT}`}>
        <SubjectName
          inputID={inputID}
          subject={subject}
          onDeleteSubject={this.props.onDeleteSubject}
        />
        <label className="my-2 text-base">
          Score:{' '}
          <input
            className="score inline"
            id={inputID}
            maxLength={3}
            size={5}
            type="number"
            value={subject.fields.score || ''}
            onChange={this._onChangeScore}
          />
        </label>
      </li>
    );
  }

  _onChangeScore = (evt: ChangeEvent<HTMLInputElement>) => {
    this.props.onUpdateSubject(this.props.subject.ID_SUBJECT, {
      score: +evt.currentTarget.value || 0,
    });
  };
}
