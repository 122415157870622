/// <reference types="@types/gtag.js" />

import {State} from 'constants';
import Plausible from 'plausible-tracker';

let state: State;

const {trackEvent, trackPageview} = Plausible({
  apiHost: __DEV__
    ? 'https://vce.beta.atarcalc.com'
    : 'https://' + location.hostname,
  domain: 'atarcalc.com',
  trackLocalhost: false,
});

export function initAnalytics(newState: State) {
  state = newState;
  trackPageview(undefined, {
    props: {state},
  });
}

function logEvent(category: string, action: string, label?: any) {
  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  } else {
    console.warn(
      `Google Analytics not loaded; can not log: ${category} ${action} ${label}`,
    );
  }
}

export function logAddSubject(name: string) {
  logEvent('Subject', name);
  trackEvent('Add Subject', {
    props: {subjectName: name, state},
  });
}

export function logChangeYear(year: number | null) {
  logEvent('Year', 'Changed', year || 'All Available');
}

let analyticsTimeout: number | null = null;
export function logCalculation() {
  // Stop any pending Analytics post
  if (analyticsTimeout != null) {
    clearTimeout(analyticsTimeout);
    analyticsTimeout = null;
  }

  // Post the event to Google Analytics after a few seconds (in case of
  // multiple consecutive updates)
  analyticsTimeout = setTimeout(() => {
    logEvent('Calculation', 'Update');
    analyticsTimeout = null;
  }, 1500);
}

export function logCourseClick(url: string): void {
  logEvent('Outbound', 'Suggested Course', url);
}

export function logSocialShareCalculation(network: string): void {
  logEvent('social', network, 'Share Calculation');
}
