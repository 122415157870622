import highlightMatch from 'autosuggest-highlight/match';
import highlightParse from 'autosuggest-highlight/parse';
import Downshift, {DownshiftState, StateChangeOptions} from 'downshift';
import {css} from 'linaria';
import {matchSorter} from 'match-sorter';
import React, {useState} from 'react';

import {AvailableSubject} from '../types';

const autocompleteList = css`
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow: auto;
  padding: 2px 0;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export type Props = {
  availableSubjects: ReadonlyArray<AvailableSubject>;

  onAddSubject: (item: {id: number; name: string}) => void;
  onFocusScore: (item: {id: number; name: string}) => void;
};

function stateReducer(
  state: DownshiftState<AvailableSubject>,
  changes: StateChangeOptions<AvailableSubject>,
): Partial<StateChangeOptions<AvailableSubject>> {
  switch (changes.type) {
    // Keep input the same when blurring (by default, Downshift replaces the
    // input with the currently selected item)
    case Downshift.stateChangeTypes.blurInput: // Blur via keyboard tab
      return {...changes, inputValue: state.inputValue};

    case Downshift.stateChangeTypes.mouseUp: // Blur via click
      return state.isOpen && !changes.isOpen
        ? {...changes, inputValue: state.inputValue}
        : changes;
  }
  return changes;
}

export default function AddSubject(props: Props) {
  const [rawValue, setRawValue] = useState('');

  // For testing Sentry
  if (rawValue === 'crashtest') {
    throw new Error('Hello world');
  }

  function onSelect(subject: AvailableSubject | null) {
    if (subject != null) {
      // For backwards compatibility with old autocompletion component, create an
      // object in the same format it used to use.
      const legacyObject = {
        id: subject.ID_SUBJECT,
        name: subject.name,
      };
      props.onAddSubject(legacyObject);
    }
    setRawValue('');
  }

  return (
    <li id="subject_li" className="subject_add">
      <Downshift
        defaultHighlightedIndex={0}
        inputValue={rawValue}
        onChange={onSelect}
        onInputValueChange={inputValue => setRawValue(inputValue)}
        stateReducer={stateReducer}
        itemToString={subject => (subject ? subject.name : '')}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
        }) => (
          <div>
            <input
              {...getInputProps({
                className: 'w-full border-0',
                placeholder: 'Add a subject - Start typing here!',
                onFocus: openMenu,
              })}
            />
            <div className="subject_autocomplete_container">
              {isOpen && (
                <ul
                  {...getMenuProps({
                    className: autocompleteList,
                  })}>
                  {getSubjectsToShow(props.availableSubjects, inputValue).map(
                    (item, index) => (
                      <li
                        {...getItemProps({
                          className:
                            'py-1 px-2 cursor-pointer' +
                            (highlightedIndex === index
                              ? ' bg-primary-500 text-white'
                              : ''),
                          key: item.ID_SUBJECT,
                          index,
                          item,
                        })}>
                        <HighlightedSubjectName
                          name={item.name}
                          inputValue={inputValue}
                        />
                      </li>
                    ),
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
      </Downshift>
    </li>
  );
}

function HighlightedSubjectName({
  name,
  inputValue,
}: {
  name: string;
  inputValue: string | null;
}) {
  if (inputValue == null || inputValue == '') {
    return <>{name}</>;
  }

  const matches = highlightParse(name, highlightMatch(name, inputValue));
  return (
    <>
      {matches.map((match, index) =>
        match.highlight ? (
          <span className="subject_autocomplete_highlight_match" key={index}>
            {match.text}
          </span>
        ) : (
          match.text
        ),
      )}
    </>
  );
}

function getSubjectsToShow(
  availableSubjects: ReadonlyArray<AvailableSubject>,
  value: string | null,
): ReadonlyArray<AvailableSubject> {
  return value == null
    ? availableSubjects
    : matchSorter(availableSubjects, value, {
        keys: ['name', 'keywords', 'area'],
      });
}
