import React, {useState, useEffect, useRef} from 'react';

import {supportsRequestAnimationFrame} from '../utils/Detect';

type Props = {
  atar: string;
};

export default function ATAR(props: Props) {
  const rawScore = props.atar.replace('Less than ', '<');
  const score = parseFloat(rawScore);
  return isNaN(score) || !supportsRequestAnimationFrame ? (
    <>{rawScore}</>
  ) : (
    <AnimatedATAR atar={score} />
  );
}

const MIN_ANIM_DURATION = 100; // ms
const ANIM_DURATION_PER_POINT = 10;
const MAX_ANIM_DURATION = 250; // ms

function AnimatedATAR({atar}: {atar: number}) {
  const [displayedScore, setDisplayedScore] = useState<number>(atar);
  const animDuration = useRef<number>(0);
  const startTime = useRef<number>(0);
  const currentFrame = useRef<number | null>(null);
  const startScore = useRef<number>(0);

  function scheduleUpdate() {
    currentFrame.current = requestAnimationFrame(updateScore);
  }

  function cancelUpdate() {
    if (currentFrame.current) {
      cancelAnimationFrame(currentFrame.current);
      currentFrame.current = null;
    }
  }

  function updateScore(currentTime: number) {
    // Work out how far along in the animation we are, based on how much time
    // has elapsed since the time it began.
    const percentComplete =
      (currentTime - startTime.current) / animDuration.current;
    if (percentComplete >= 1) {
      // Animation should be over, just display the final score and get out.
      setDisplayedScore(atar);
      return;
    }

    // Difference between the currently displayed score, and the new score we
    // want to display
    const diff = atar - startScore.current;
    const newDisplayedScore = startScore.current + diff * percentComplete;
    scheduleUpdate();
    setDisplayedScore(newDisplayedScore);
  }

  // When a new score is being displayed, animate a transition to it.
  useEffect(() => {
    cancelUpdate();
    startTime.current = performance.now();
    startScore.current = +displayedScore;

    // Difference between the currently displayed score, and the new score we
    // want to display
    const diff = Math.abs(atar - startScore.current);
    // Compute animation duration based on the difference - Larger differences
    // will animate for a longer period of time
    animDuration.current = Math.min(
      MAX_ANIM_DURATION,
      Math.max(MIN_ANIM_DURATION, diff * ANIM_DURATION_PER_POINT),
    );

    scheduleUpdate();
    return cancelUpdate;
  }, [atar]);

  return <>{displayedScore.toFixed(2)}</>;
}
