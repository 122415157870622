import React from 'react';

import AddSubject, {Props as AddSubjectProps} from './AddSubject';
import Results, {Props as ResultsProps} from './Results';
import SubjectBasic from './SubjectBasic';
import {Subject, SubjectFields, ExtraData} from '../types';

export type Props = AddSubjectProps &
  ResultsProps & {
    extraData: ExtraData;
    extraHeader?: React.ReactNode | null;
    subjects: ReadonlyArray<Subject>;
    subjectRenderer?: (subject: Subject) => React.ReactNode;

    onChangeExtraData: (key: string, value: string) => void;
    onDeleteSubject: (id: number) => void;
    onUpdateSubject: (id: number, fields: SubjectFields) => void;
  };

const boxClass =
  'item block group bg-white text-gray-700 shadow rounded-lg list-item list-none grid gap-4 auto-cols-auto grow divide-y divide-gray-200 h-fit';

export default class CalculatorCore extends React.Component<Props> {
  render() {
    return (
      <div className="list grid gap-4 auto-cols-auto md:grid-cols-2">
        <ul className={boxClass} id="subjects">
          <li className="py-3 px-4">
            <h2 className="title mb-0 text-lg mb-1 mb-1">Your Subjects</h2>
          </li>
          {this.props.extraHeader}
          {this.props.subjects.map(
            this.props.subjectRenderer || this._defaultSubjectRenderer,
            this,
          )}
          <AddSubject {...this.props} />
        </ul>
        <div className={boxClass}>
          <Results {...this.props} />
        </div>
      </div>
    );
  }

  _defaultSubjectRenderer = (subject: Subject) => {
    return (
      <SubjectBasic
        key={subject.ID_SUBJECT}
        subject={subject}
        onDeleteSubject={this.props.onDeleteSubject}
        onUpdateSubject={this.props.onUpdateSubject}
      />
    );
  };
}
