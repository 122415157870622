export type SerializableData = string | number | null | undefined;

/**
 * Querystring encodes the specified data.
 */
export default function buildQueryString(data: {
  [key: string]: SerializableData;
}): string {
  const serializedData = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      serializedData.push(`${key}=${encodeURIComponent('' + data[key])}`);
    }
  }
  return serializedData.join('&');
}
