/**
 * Browser feature detection utilities.
 */

export let supportsLocalStorage = false;
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
try {
  const storage = window.localStorage;
  const x = '__storage_test__';
  storage.setItem(x, x);
  storage.removeItem(x);
  supportsLocalStorage = true;
} catch (e) {
  // Browser doesn't support local storage, or the user has disabled it for this domain
}

export const supportsRequestAnimationFrame = !!(
  // @ts-ignore
  (window.requestAnimationFrame && performance.now)
);

const isFetchSupported = !!window.fetch;
export const isBrowserTooOld = !isFetchSupported;
