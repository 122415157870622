import {useReducer, useCallback} from 'react';

import {
  AppConfig,
  ExtraData,
  NewSubject,
  Subject,
  SubjectFields,
} from '../types';
import {State as CalcState} from '../constants';
import {replaceAtIndex} from '../utils/ArrayUtils';

export enum ActionType {
  ADD_PLACEHOLDER_SUBJECT = 'ADD_PLACEHOLDER_SUBJECT',
  ADD_SUBJECT = 'ADD_SUBJECT',
  CHANGE_EXTRA_DATA = 'CHANGE_EXTRA_DATA',
  CHANGE_YEAR = 'CHANGE_YEAR',
  DELETE_SUBJECT = 'DELETE_SUBJECT',
  REPLACE_SUBJECT = 'REPLACE_SUBJECT',
  SET_STATE = 'SET_STATE',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT',
}

export type Action =
  | {
      type: ActionType.ADD_PLACEHOLDER_SUBJECT;
    }
  | {
      type: ActionType.ADD_SUBJECT;
      subject: NewSubject;
    }
  | {
      type: ActionType.CHANGE_EXTRA_DATA;
      field: string;
      value: string | number;
    }
  | {
      type: ActionType.CHANGE_YEAR;
      year: number | null;
    }
  | {
      type: ActionType.DELETE_SUBJECT;
      id: number;
    }
  | {
      type: ActionType.REPLACE_SUBJECT;
      oldID: number;
      newID: number;
    }
  | {
      type: ActionType.SET_STATE;
      extraData: ExtraData;
      subjects: ReadonlyArray<NewSubject>;
    }
  | {
      type: ActionType.UPDATE_SUBJECT;
      id: number;
      fields: SubjectFields;
    };

export type State = {
  extraData: ExtraData;
  selectedYear: number | null;
  subjects: ReadonlyArray<Subject>;
};

function reduce(appConfig: AppConfig, state: State, action: Action): State {
  switch (action.type) {
    case ActionType.ADD_PLACEHOLDER_SUBJECT:
      return addPlaceholderSubject(appConfig, state);

    case ActionType.ADD_SUBJECT:
      return addSubject(appConfig, state, action.subject);

    case ActionType.CHANGE_EXTRA_DATA:
      return {
        ...state,
        extraData: {...state.extraData, [action.field]: action.value},
      };

    case ActionType.CHANGE_YEAR:
      return {...state, selectedYear: action.year};

    case ActionType.DELETE_SUBJECT:
      return {
        ...state,
        subjects: state.subjects.filter(
          subject => subject.ID_SUBJECT !== action.id,
        ),
      };

    case ActionType.REPLACE_SUBJECT:
      return updateSubject(state, action.oldID, {ID_SUBJECT: action.newID});

    case ActionType.SET_STATE:
      let newState = {
        ...state,
        extraData: action.extraData,
        subjects: [] as ReadonlyArray<Subject>,
      };
      action.subjects.forEach(subject => {
        newState = addSubject(appConfig, newState, subject);
      });
      return newState;

    case ActionType.UPDATE_SUBJECT:
      return updateSubject(state, action.id, {fields: action.fields});

    default:
      return state;
  }
}

/**
 * Determine whether the specified subject already exists in the current
 * calculation.
 */
function isSubjectSelected(state: State, id: number): boolean {
  return state.subjects.some(subject => subject.ID_SUBJECT === id);
}

function addSubject(
  appConfig: AppConfig,
  state: State,
  {id, name, fields}: NewSubject,
): State {
  // Don't add subject if it's already selected
  if (isSubjectSelected(state, id)) {
    return state;
  }

  const subject = appConfig.availableSubjects.find(
    subject => subject.ID_SUBJECT == id,
  );

  if (!name) {
    if (!subject) {
      // Don't do anything if the ID is invalid. This could be from a user
      // using an old permalink URL containing a subject that no longer
      // exists.
      return state;
    }
    name = subject.name;
  }

  if (!fields) {
    fields = {};
    if (appConfig.state === CalcState.WACE) {
      // Default to stage 3 for WACE subjects
      fields.stage = '3';
    }
  }

  return {
    ...state,
    subjects: state.subjects.concat({
      fields: fields,
      ID_SUBJECT: id,
      name,
      subjectType: subject ? subject.subjectType : null,
    }),
  };
}

/**
 * Adds the first available unused subject.
 */
function addPlaceholderSubject(appConfig: AppConfig, state: State): State {
  let i = -1;
  do {
    i++;
  } while (
    isSubjectSelected(state, appConfig.availableSubjects[i].ID_SUBJECT) &&
    i < appConfig.availableSubjects.length - 1
  );

  const subject = appConfig.availableSubjects[i];
  return addSubject(appConfig, state, {
    id: subject.ID_SUBJECT,
    name: subject.name,
  });
}

function updateSubject(
  state: State,
  id: number,
  updates: Partial<Subject>,
): State {
  const subjectIndex = state.subjects.findIndex(
    subject => subject.ID_SUBJECT === id,
  );
  if (subjectIndex === -1) {
    return state;
  }

  const newSubject = {
    ...state.subjects[subjectIndex],
    ...updates,
  };

  return {
    ...state,
    subjects: replaceAtIndex(state.subjects, subjectIndex, newSubject),
  };
}

function getInitialState(appConfig: AppConfig): State {
  const extraData: ExtraData = {};
  if (appConfig.state === CalcState.VCE) {
    extraData.increment = 0;
  }
  return {
    extraData,
    selectedYear: appConfig.currentYear,
    subjects: [],
  };
}

export default function useCalculatorInputReducer(appConfig: AppConfig) {
  const reducer = useCallback(
    (state: State, action: Action) => reduce(appConfig, state, action),
    [appConfig.availableSubjects, appConfig.currentYear, appConfig.state],
  );
  return useReducer(reducer, getInitialState(appConfig));
}
