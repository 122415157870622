/**
 * Replaces the item at the given array index. Returns a mutated clone of the
 * array.
 */
export function replaceAtIndex<T>(
  arr: ReadonlyArray<T>,
  index: number,
  newItem: T,
): Array<T> {
  return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)];
}
