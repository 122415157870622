import {css} from 'linaria';

export const table = css`
  background: white;
  border-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  th,
  td {
    border-bottom: 1px solid rgb(var(--colors-gray-200));
  }
`;

export const select = css`
  appearance: auto;
  margin: 0;
  padding: 2px 10px;
`;
