import buildQueryString, {SerializableData} from '../utils/buildQueryString';

import {useRef, useState, useEffect} from 'react';

declare global {
  interface Window {
    // Avoids TS2339: Property 'AbortController' does not exist on type 'Window'.
    AbortController?: typeof AbortController;
  }
}

type Request = {
  data: {[key: string]: SerializableData};
  shouldSendRequest?: boolean;
  url: string;
};

export default function useAjax<TResponse>({
  data,
  shouldSendRequest = true,
  url,
}: Request): [boolean, TResponse | null, Error | null] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [response, setResponse] = useState<TResponse | null>(null);
  const abortLastRequest = useRef<(() => void) | null>(null);
  const lastQueryString = useRef<string | null>(null);

  function abortPendingRequest() {
    if (abortLastRequest.current != null) {
      abortLastRequest.current();
      abortLastRequest.current = null;
    }
    setIsLoading(false);
  }
  useEffect(() => {
    const queryString = buildQueryString(data);
    if (queryString === lastQueryString.current) {
      // Request is identical, don't bother doing anything
      return;
    }

    lastQueryString.current = queryString;
    abortPendingRequest();

    if (!shouldSendRequest) {
      setIsLoading(false);
      setResponse(null);
      setError(null);
      return;
    }

    const {abort, request} = abortableFetch<TResponse>(url, queryString);
    abortLastRequest.current = abort;
    setIsLoading(true);
    request.then(
      newResponse => {
        setIsLoading(false);
        setError(null);
        setResponse(newResponse);
        abortLastRequest.current = null;
      },
      (e: Error) => {
        if (e.name === 'AbortError') {
          return;
        }
        setIsLoading(false);
        setResponse(null);
        setError(e);
        abortLastRequest.current = null;
      },
    );
    return abortPendingRequest;
  }, [url, data]);
  return [isLoading, response, error];
}

const supportsAbort = !!window.AbortController;
function abortableFetch<TResponse>(
  url: string,
  postData: string,
): {abort: () => void; request: Promise<TResponse>} {
  const fetchSettings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: postData,
  };
  if (supportsAbort) {
    const controller = new AbortController();
    const request = fetch(url, {
      ...fetchSettings,
      signal: controller.signal,
    }).then(response => jsonOrThrow<TResponse>(response));
    return {
      abort: () => controller.abort(),
      request,
    };
  }

  // For legacy browsers - Can be deleted one day
  // With no AbortController support, we can't actually abort the request, but we can at least
  // ignore the response.
  let wasAborted = false;
  const request = fetch(url, fetchSettings)
    .then(response => {
      if (wasAborted) {
        const error = new Error('Aborted');
        error.name = 'AbortError';
        throw error;
      }
      return response;
    })
    .then(response => jsonOrThrow<TResponse>(response));
  return {
    abort: () => (wasAborted = true),
    request,
  };
}

function jsonOrThrow<TResponse>(response: Response): Promise<TResponse> {
  return response.ok
    ? response.json()
    : response.text().then(response => {
        throw new Error(response);
      });
}
