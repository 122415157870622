import {Subject} from '../types';

type SerializedData = {[key: string]: string | number | undefined};

/**
 * Serializes the specified subjects into a format suitable for API requests.
 */
export default function serializeSubjects(subjects: ReadonlyArray<Subject>) {
  const data: SerializedData = {};
  subjects.forEach(subject => {
    const fields = subject.fields;

    // Skip subjects that don't have a score entered
    if (!fields.score && !fields.grade) {
      return;
    }

    const onlyHasScore = !fields.hasOwnProperty('stage');
    const grade = subject.fields.grade;

    if (onlyHasScore) {
      // Only a score field, serialize as score[id]=50
      if (fields.score) {
        data[`score[${subject.ID_SUBJECT}]`] = fields.score;
      }
      const grade = subject.fields.grade;
      if (grade) {
        data[`grade[${subject.ID_SUBJECT}]`] = grade;
      }
    } else {
      // TODO: Is this code still needed? We might not have any `stage` any more?
      if (fields.score) {
        data[`subjects[${subject.ID_SUBJECT}][score]`] = subject.fields.score;
      }
      if (grade != null) {
        data[`subjects[${subject.ID_SUBJECT}][grade]`] = grade;
      }
      if (fields.stage) {
        data[`subjects[${subject.ID_SUBJECT}][stage]`] = subject.fields.stage;
      }
    }
  });
  return data;
}
