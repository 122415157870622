import {useEffect, useCallback, useRef, Dispatch, ReducerAction} from 'react';

import {State as CalcState} from '../constants';
import {AppConfig, ExtraData, NewSubject} from '../types';
import {State, Action, ActionType} from './useCalculatorInputReducer';
import {supportsLocalStorage} from '../utils/Detect';
import serializeSubjects from '../utils/serializeSubjects';

const isSupported = !!window.addEventListener;

export default function useCalculatorURL(
  appConfig: AppConfig,
  state: State,
  dispatch: Dispatch<Action>,
) {
  if (!isSupported) {
    return;
  }

  const ignoreStateChanges = useRef<boolean>(false);
  const isInitialLoad = useRef<boolean>(false);

  const setAppStateFromHash = useCallback((state: any) => {
    ignoreStateChanges.current = true;

    // Go through all keys in persisted state to try and find subjects
    // Use an array to preserve order, but also keep a map for quick lookups.
    let subjects: Array<NewSubject> = [];
    let subjectsMap: {[id: number]: NewSubject} = {};
    let extraData: ExtraData = {};

    for (let key in state) {
      if (!state.hasOwnProperty(key)) {
        continue;
      }

      let value = state[key];

      // Used when state only has one field for scores: score[id]=50
      let matchesSimpleScore = key.match(/^score\[(\d+)\]$/);
      if (matchesSimpleScore) {
        let subject = {
          id: +matchesSimpleScore[1],
          fields: {
            score: value,
          },
        };
        subjects.push(subject);
        continue;
      }

      // Used when subject uses a letter grade: grade[id]=B
      const matchesGrade = key.match(/^grade\[(\d+)\]$/);
      if (matchesGrade) {
        let subject = {
          id: +matchesGrade[1],
          fields: {
            grade: value,
          },
        };
        subjects.push(subject);
        continue;
      }

      // Used when state has multiple fields:
      // subjects[id][foo]=bar&subjects[id][score]=12
      let matchesComplexScore = key.match(/^subjects\[(\d+)\]\[(.+)\]$/);
      if (matchesComplexScore) {
        let id = +matchesComplexScore[1];
        let fieldName = matchesComplexScore[2];
        let subject = subjectsMap[id];
        if (!subject) {
          subject = {
            id: id,
            fields: {},
          };
          subjects.push(subject);
          subjectsMap[id] = subject;
        }

        switch (fieldName) {
          case 'grade':
          case 'scaling':
          case 'score':
          case 'stage':
            subject.fields = {
              ...subject.fields,
              [fieldName]: value,
            };
        }
        continue;
      }

      // Left over field, shove it into extraData
      extraData[key] = value;
    }
    dispatch({
      type: ActionType.SET_STATE,
      extraData,
      subjects,
    });
    ignoreStateChanges.current = false;
  }, []);

  const onHashChange = useCallback(() => {
    if (ignoreStateChanges.current) {
      return;
    }

    // Trim the "#" from the front if applicable
    let hashString = window.location.hash;
    if (hashString && hashString.charAt(0) === '#') {
      hashString = hashString.substr(1);
    }

    // If the hash is empty, we want to clear the UI
    // Otherwise, parse the hash as JSON.
    let hash = {};
    if (hashString.length !== 0) {
      try {
        hash = JSON.parse(hashString);
      } catch (ex) {
        // It's possible the hash was URL encoded...
        try {
          hash = JSON.parse(decodeURIComponent(hashString));
        } catch (ex) {
          console.warn('Invalid JSON while parsing hash: ', ex);
        }
      }
    }
    setAppStateFromHash(hash);
  }, []);

  // Initialization
  useEffect(() => {
    // If we already have a hash, the UI needs to be updated to reflect it.
    if (window.location.hash) {
      // Temporarily ignore state changes so that the hash isn't blown away on initial load
      isInitialLoad.current = true;
      onHashChange();
      window.setTimeout(() => (isInitialLoad.current = false), 30);
    } else if (supportsLocalStorage) {
      const storedURL = window.localStorage.getItem(
        getStorageKey(appConfig.state),
      );
      if (storedURL) {
        window.location.hash = storedURL;
        onHashChange();
      }
    }

    window.addEventListener('hashchange', onHashChange, false);

    return () => {
      window.removeEventListener('hashchange', onHashChange, false);
    };
  }, []);

  // Persist UI updates to the URL
  useEffect(() => {
    if (ignoreStateChanges.current || isInitialLoad.current || !isSupported) {
      return;
    }

    ignoreStateChanges.current = true;
    window.location.hash = JSON.stringify({
      ...state.extraData,
      ...serializeSubjects(state.subjects),
    });

    // Save URL to local storage too, so we can reload the same calculation if the user comes
    // back later.
    if (supportsLocalStorage) {
      window.localStorage.setItem(
        getStorageKey(appConfig.state),
        window.location.hash,
      );
    }

    // Temporarily ignore state changes for the next few ms
    // This is to account for the fact that hashchange isn't called straight after window.location.hash
    // is changed.
    window.setTimeout(() => (ignoreStateChanges.current = false), 30);
  }, [state.extraData, state.subjects]);
}

const getStorageKey = (state: CalcState) => `${state}_latestURL`;
