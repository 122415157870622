import React, {ChangeEvent} from 'react';

import Errors from './Errors';
import Estimate from './Estimate';
import ResultsAggregates from './ResultsAggregates';
import ResultsSubjects from './ResultsSubjects';
import ShareResults from './ShareResults';
import {ResultsData, YearResult} from '../types/ResultTypes';
import {State} from '../constants';
import {select} from '../sharedStyles';
import {Subject} from '../types';

const ALL_AVAILABLE = 'ALL_AVAILABLE';

export type Props = {
  currentYear: number;
  data: ResultsData | null;
  isLoading: boolean;
  scaledTitle?: string | null;
  selectedYear: number | null;
  showRaw?: boolean;
  state: State;
  subjects: ReadonlyArray<Subject>;
  yearsToShow: ReadonlyArray<number>;

  onSelectedYearChanged: (year: number | null) => void;
};

export default class Results extends React.Component<
  Props,
  {throwError: boolean}
> {
  render() {
    (window as any)._____throwHelloWorld = this._throwHelloWorld;
    if (this.state?.throwError) {
      throw new Error('hello world React');
    }
    return (
      <>
        {this._renderHeader()}
        {this._renderPlaceholder()}
        <Errors errors={this.props.data && this.props.data.errors} />
        {this.props.data && (
          <div className="mx-auto">
            {this._renderEstimate()}
            {this._renderResults()}
          </div>
        )}
        <br />
        {this._renderAggregates()}
      </>
    );
  }

  _throwHelloWorld = () => {
    this.setState({throwError: true});
  };

  _renderHeader() {
    const title =
      this.props.yearsToShow.length === 1 ? (
        <>Your results</>
      ) : (
        <>Your results for {this._renderYearSelector()}</>
      );

    return (
      <div className="py-3 px-4 flex justify-between">
        <h2 className="title mb-0 text-lg mb-1 mb-1">{title}</h2>
        <div>{this._renderShare()}</div>
      </div>
    );
  }

  _renderYearSelector() {
    return (
      <select
        className={select}
        value={this.props.selectedYear || ALL_AVAILABLE}
        onChange={this._onYearChange}>
        {this.props.yearsToShow.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
        <option value={ALL_AVAILABLE}>All available</option>
      </select>
    );
  }

  _renderEstimate() {
    var data = this._getSelectedYearData();
    if (!data || !data.enter) {
      return null;
    }
    return (
      <Estimate
        aggregate={'' + data.aggregate}
        atar={'' + data.enter}
        isLoading={this.props.isLoading}
        state={this.props.state}
      />
    );
  }

  _renderResults() {
    if (!this.props.data || !this.props.data.subjects) {
      return null;
    }

    var yearsToShow = this.props.selectedYear
      ? [this.props.selectedYear]
      : this.props.yearsToShow;
    // Show the description if any subjects have one
    let showDesc =
      this.props.data.subjects.length === 0 ||
      this.props.data.subjects.some(subject => !!subject.desc);

    const subjectInputAndResults = this.props.data.subjects.map(subject => {
      const subjectInput = this.props.subjects.find(
        x => x.ID_SUBJECT === subject.id,
      );
      return {
        result: subject,
        fields: subjectInput && subjectInput.fields,
      };
    });

    return (
      <ResultsSubjects
        scaledTitle={this.props.scaledTitle}
        showDesc={showDesc}
        showRaw={this.props.showRaw}
        subjects={subjectInputAndResults}
        yearsToShow={yearsToShow}
      />
    );
  }

  _renderAggregates() {
    if (this.props.selectedYear || !this.props.data) {
      return null;
    }
    return (
      <ResultsAggregates
        aggregates={this.props.data.years}
        yearsToShow={this.props.yearsToShow}
      />
    );
  }

  _renderShare() {
    if (!this.props.data || !this.props.data.years) {
      return null;
    }
    var data =
      this.props.data.years[this.props.selectedYear || this.props.currentYear];
    if (!data) {
      return null;
    }
    return <ShareResults atar={+data.enter} state={this.props.state} />;
  }

  _renderPlaceholder() {
    return this.props.data ? null : (
      <div className="px-5 py-5">
        Once you enter some data, your results will be shown here.
      </div>
    );
  }

  _getSelectedYearData(): YearResult | null {
    if (this.props.selectedYear == null) {
      return null;
    }
    return (
      this.props.data &&
      this.props.data.years &&
      this.props.data.years[this.props.selectedYear]
    );
  }

  _onYearChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const year = evt.target.value === ALL_AVAILABLE ? null : +evt.target.value;
    this.props.onSelectedYearChanged(year);
  };
}
