/// <reference types="@types/facebook-js-sdk" />

import {css} from 'linaria';
import React, {MouseEvent} from 'react';

import buildQueryString from '../utils/buildQueryString';
import {State} from '../constants';
import {logSocialShareCalculation} from '../analyticsLogger';

type Props = {
  atar: number;
  state: State;
};

const shareButton = css`
  padding-left: 30px;
`;

const facebook = css`
  background: url(../../img/atarnotes/facebook.png) no-repeat 10px center;
  background-color: #507abd;
`;

const twitter = css`
  //background: url(../../img/atarnotes/twitter.png) no-repeat 10px center;
  background-color: #63ccee;
`;

export default class ShareResults extends React.Component<Props> {
  render() {
    return (
      <div>
        <a
          className={`py-1 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-inset font-medium tracking-tight transition items-center text-left shadow-lg rounded-lg subject relative group overflow-hidden mb-2 text-white ${shareButton} ${facebook}`}
          href="#"
          title="Share on Facebook"
          onClick={this._facebook}>
          share
        </a>{' '}
        <a
          className={`py-1 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-inset font-medium tracking-tight transition items-center text-left shadow-lg rounded-lg subject relative group overflow-hidden mb-2 text-white ${shareButton} ${twitter}`}
          title="Share on Twitter"
          href="#"
          onClick={this._twitter}>
          share
        </a>
      </div>
    );
  }

  _twitter = (e: MouseEvent<HTMLAnchorElement>) => {
    const width = 550,
      height = 420,
      left = Math.round(screen.width / 2 - width / 2),
      top = Math.round(screen.height / 2 - height / 2);

    const params = {
      text: 'I estimated my ATAR to be ' + this.props.atar + ' at',
      url: 'https://' + this.props.state + '.atarcalc.com/',
      related: 'Daniel15:Creator of the ATAR Calculator',
    };

    const url = 'https://twitter.com/intent/tweet?' + buildQueryString(params);

    window.open(
      url,
      'intent',
      'width=' +
        width +
        ',height=' +
        height +
        ',left=' +
        left +
        ',top=' +
        top +
        ',scrollbars=yes,resizable=yes,toolbar=no,location=yes',
    );

    e.stopPropagation();
    e.preventDefault();
    logSocialShareCalculation('twitter');
  };

  _facebook = (e: MouseEvent<HTMLAnchorElement>) => {
    FB.ui({
      method: 'share',
      href: 'https://' + this.props.state + '.atarcalc.com/',
      hashtag: '#atarcalc',
      quote: 'I estimated my ATAR to be ' + this.props.atar,
    });

    e.stopPropagation();
    e.preventDefault();
    logSocialShareCalculation('facebook');
  };
}
